<template>
  <div>
    <a-button-group>
      <a-popconfirm v-if="item.inspection_status === 'pending'" title="确定合格吗" @confirm="markPass">
        <a-button type="primary" size="small">合格</a-button>
      </a-popconfirm>
      <a-popconfirm v-if="item.inspection_status === 'pending'" title="确定不合格吗" @confirm="markFail">
        <a-button type="danger" size="small">不合格</a-button>
      </a-popconfirm>
      <a-button v-if="item.inspection_status !== 'pending'" size="small" @click="handlePrint">打印</a-button>
    </a-button-group>
  </div>
</template>

<script>
import { processInspectionTaskPass, processInspectionTaskFail } from "@/api/inspection";
import printOrder from "./printOrder";

export default {
  components: {},
  props: ["item"],
  data() {
    return {
      passLoading: false,
      failLoading: false,
    };
  },
  methods: {
    update(item) {
      this.$emit("update", item);
    },
    markPass() {
      this.passLoading = true;
      processInspectionTaskPass({ id: this.item.id })
        .then(() => {
          this.$message.success("操作成功");
          this.update({ ...this.item, inspection_status: "passed" });
        })
        .finally(() => {
          this.passLoading = false;
        });
    },
    markFail() {
      this.failLoading = true;
      processInspectionTaskFail({ id: this.item.id })
        .then(() => {
          this.$message.success("操作成功");
          this.update({ ...this.item, inspection_status: "failed" });
        })
        .finally(() => {
          this.failLoading = false;
        });
    },
    handlePrint() {
      printOrder(this.item);
      console.log(this.item);
    },
  },
};
</script>

<style scoped></style>
